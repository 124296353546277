<template>
  <b-card title="Applied Equipment Warranty List">
    <b-modal v-model="modalDelete" title="Delete Applied Equipment Warranty">
      <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to delete this {{ selectEquipment.name }} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="doDelete"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>

    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button
            v-if="permission.add"
            variant="success"
            @click="addEquipment"
          >
            Add Equipment
          </b-button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            @input="(value) => nameFiltering(value)"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-table :fields="fields" :items="equipments" show-empty responsive>
        <template #cell(topDate)="row">
          {{ row.item.topDate | formatDate }}
        </template>
        <template #cell(action)="row">
          <b-dropdown
            boundary="window"
            offset="-50"
            variant="primary"
            text="Menu"
            no-caret
          >
            <template #button-content>
              <feather-icon size="1x" icon="MenuIcon" />
            </template>
            <b-dropdown-item style="text-align:center" v-if="permission.edit">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="doEditEquipment(row.item._id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item style="text-align:center" v-if="permission.delete">
              <b-button
                variant="danger"
                size="sm"
                @click="deleteEquipment(row.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-dropdown-item>

            <b-dropdown-item active @click="letterEquipment(row.item._id)">
              Generate Warranty Certificate
            </b-dropdown-item>
            <!-- <b-dropdown-item
              active
              @click="letterResident(row.item._id)"
            >
              Print Warranty for Residential
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12">
      <b-pagination
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        style="float: right"
        @change="onPageChange"
      />
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { userAccess, downloadByPDFMake } from "@/utils/utils";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import {
  logo,
  footer,
  header,
} from "../../delivery-module/actions-detail/daikin-img-base64";
import lodash from "lodash";

export default {
  data() {
    return {
      fields: [
        { key: "name", text: "Name", filterable: true, sortable: true },
        { key: "address", text: "Address" },
        { key: "topDate", text: "Top Date" },
        { key: "developer", text: "Developer" },
        { key: "email", text: "Email", filterable: true, sortable: true },
        {
          key: "refNum",
          text: "Cert Number",
          filterable: true,
          sortable: true,
        },
        { key: "action", text: "Action" },
      ],
      form: {
        primary: [],
        secondary: [],
        module: "",
        subModule: "",
        name: "",
      },
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      search: "",
      selectEquipment: "",
      modalDelete: false,
      getDataEquipment: "",
      daikinHeader: require("@/assets/images/drcc/Daikin_Header.png"),
      daikinFooter: require("@/assets/images/drcc/Daikin_Footer.png"),
    };
  },
  mounted() {
    setTimeout(() => {
      axios
        .get(`/sales-and-purchase/drcc/term-by/applied equipment warranty/`)
        .then((res) => {
          console.log("res__data", res.data);
          this.form = res.data.data;
        })
        .catch((err) => console.log({ err }));
    }, 300);
    this.queryEquipmentWarranty();
  },
  methods: {
    ...mapActions({
      getAllEquipments: "equipmentWarranty/getEquipmentWarranty",
      deleteEquipment: "equipmentWarranty/deleteEquipment",
      getEquipmentWarranty: "equipmentWarranty/getEquipment",
    }),
    nameFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryEquipmentWarranty();
    }, 500),
    onPageChange(page) {
      this.currentPage = page;
      this.queryEquipmentWarranty();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    queryEquipmentWarranty() {
      this.getAllEquipments({
        page: this.currentPage,
        entry: this.perPage,
        search: this.search,
      })
        .then((data) => {
          this.processMetadata(data.metadata);
          this.isLoading = false;
        })
        .catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          console.log(e);
        });
    },
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = url;
      });
    },
    async letterEquipment(id) {
      let getData = {};
      await this.getEquipmentWarranty(id)
        .then((res) => {
          getData = res;
          console.log(res);
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataEquipment
      console.log("pro", getData);
      let cert = `${getData.refNum}`;
      // let monthText = getData.warrantyDuration === 15 ?'fifteen':'eighteen'
      let monthText = "";
      switch (getData.warrantyDuration) {
        // case 15:
        //   monthText = 'fifteen'
        //   break;
        case 18:
          monthText = "eighteen";
          break;

        default:
          monthText = "twelve";
          break;
      }
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);
      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.warrantyDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        let dates = new Date(date);
        let options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },

          { text: `${getData.developer}` },
          { text: `${getData.address}` },
          { text: `${getData.country} ${getData.postalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sirs \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above address shall commence on  ${transformTopDate}. \n`,
          },
          { text: `\n` },

          {
            text: `Please refer to Annex A for the list of items covered in this warranty. \n`,
          },
          { text: `\n` },

          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          // {text: `Defect Liability Period and/or Warranty (“Warranty"): \n`,fontSize:13, bold:true},

          { text: `\n` },
          {
            text: `The warranty period for the chiller, air handling unit and chilled water fan coil (“Applied Products”)  is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },

          { text: "Annex A \n", bold: true, pageBreak: "before", fontSize: 13 },
          {
            style: "tableExample",
            color: "#444",
            table: {
              widths: ["30%", "20%", "25%", "25%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial No",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  { text: "Other Parts", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
          },
        ],
      };

      for (let i = 0; i < getData.items.length; i++) {
        const el = getData.items[i];
        let otherPart = dateTransform(el.generalEndWarranty);
        let compressor = el.compressorEndWarranty
          ? dateTransform(el.compressorEndWarranty)
          : "-";
        docDefinition.content[25].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
      }
      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },

    addEquipment() {
      this.$router.push(`/warranty/equipment-warranty/new`);
    },
    doEditEquipment(idEquipment) {
      this.$router.push(`/warranty/equipment-warranty/edit/${idEquipment}`);
    },
    doDelete() {
      // this.deleteEquipment(this.selectEquipment._id)
      axios
        .delete(
          `sales-and-purchase/equipment-warranty/${this.selectEquipment._id}`
        )
        .then(async (equipment) => {
          console.log({ equipment });
          this.equipment = equipment;
          this.$bvToast.toast(
            `Delete Applied Equipment Warranty Successfully`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.getAllEquipments();
          this.modalDelete = false;
        })
        .catch((e) => {
          console.log({ e });
          if (e.request.status === 400) {
            this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`${e.response.data.message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    deleteEquipment(row) {
      this.selectEquipment = row;
      this.modalDelete = true;
    },
  },
  computed: {
    ...mapState({
      //equipments: (state) => state.equipmentWarranty.equipments,
      // termsCondition : (state) => state.TermCondition
    }),
    equipments() {
      console.info(
        this.$store.getters["equipmentWarranty/getEquipmentWarranties"]
      );
      return this.$store.getters["equipmentWarranty/getEquipmentWarranties"];
    },
    terms() {
      let final = [];
      let primary = this.form.primary;
      let secondary = this.form.secondary;
      for (let i = 0; i < primary.length; i++) {
        let temp = [];
        if (secondary[i].length === 0) {
          temp.push({ text: primary[i], margin: [0, 10, 0, 5] });
        } else {
          temp.push({ text: primary[i], margin: [0, 10, 0, 0] });
        }
        if (secondary[i].length !== 0) {
          temp.push({ ul: secondary[i] });
        }
        final.push(temp);
      }
      return final;
    },
    permission() {
      return userAccess("Equipment Warranty", "warranty_menu");
    },
    dataSearch() {
      let paginationLength;
      paginationLength = this.filteredEquipment;
      if (this.search != "") {
        return paginationLength.length;
      } else {
        return this.equipments.length;
      }
    },
  },
  created() {
    document.title = "Applied Equipment Warranty | RSP";
  },
};
</script>
